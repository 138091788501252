import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { AppGlobals } from './app.globals';
import { AppDataProvider } from './app.data.provider';
import { User } from './models/user.model';
import { LookupIcdCodesPage } from './lookup-icd-codes/lookup-icd-codes.page';
import { OverlayEventDetail } from '@ionic/core';
import { LookupProcedureCodesPage } from './lookup-procedure-codes/lookup-procedure-codes.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  name: string;
  email: string;

  constructor(
    private route: Router,
    private http: HttpClient,
    private appDataProvider: AppDataProvider
  ) {}

  ngOnInit() {
    this.authenticateUser();
  }

  authenticateUser() {
    if (!localStorage.getItem('user')) {
      this.route.navigate(['/login']);
    } else {
      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('x-access-token', localStorage.getItem('token'));

      this.http
        .post<any>(AppGlobals.URL + 'auth/me', null, { headers })
        .subscribe(
          async (data) => {
            this.loadLookupData();
            this.appDataProvider.setUser(data);
            // console.log(data);
            // this.name =
            //   this.appDataProvider.user.name +
            //   ' ' +
            //   this.appDataProvider.user.surname;
            // this.email = this.appDataProvider.user.email;

            if (!this.appDataProvider.user) {
              this.route.navigate(['/login']);
            } else {
              this.route.navigate(['/folder/Inbox']);
              // this.route.navigate(['/create-quote']);
            }
          },
          async (err) => {
            this.route.navigate(['/login']);
          }
        );
    }
  }

  loadLookupData() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.http.get<any>(AppGlobals.URL + 'lookup', { headers }).subscribe(
      async (data) => {
        this.appDataProvider.setLookupData(data);
      },
      async (err) => {}
    );
  }

  logout() {
    if (confirm('Are you sure you want to logout?')) {
      localStorage.clear();
      this.appDataProvider.setUser(null);
      this.route.navigate(['/login']);
    }
  }

  signedin() {
    if (!localStorage.getItem('user')) {
      return false;
    } else {
      return true;
    }
  }

  isadmin() {
    if (
      this.signedin() &&
      this.appDataProvider.user &&
      this.appDataProvider.user.userroleid === 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  managecodes(codes) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        codes: JSON.stringify(codes),
      },
    };
    this.route.navigate(['/list-codes'], navigationExtras);
  }
}
